(function ($) {
"use strict";

  $(function() {
    $('.hamburger').on('click', function(e) {
      $('body').toggleClass('offsetmenu-open');
      $('#offset-menu, .hamburger').toggleClass('open');
      e.stopPropagation();
    });

    $(document).on("click", function(e) {
      if ($(e.target).is("#offset-menu *, .hamburger *") === false) {
        $('body').removeClass('offsetmenu-open');
        $('#offset-menu, .hamburger').removeClass('open');
      }
    });
  });

  $(window).scroll(function() {
    scroll = $(window).scrollTop();

    if (scroll >= 50) {
      $('body').addClass('sticky-header');
    } else {
      $('body').removeClass('sticky-header');
    }
  });


  $(function() {
    var i = 0;
    $('.with-sidebar').find('h3').each(function() {
      $(this).attr('id', i);

      var naslov = $(this).html();
      var slika = $(this).parent().find('#'+i+' ~ p > img').attr('src');

      if (naslov === "Nižinske in montanske do alpinske hidrofilne robne združbe z visokim steblikovjem") {
        naslov = "Visoko steblikovje";
      }

      $('.sidebar').append('<a class="link" href="' + window.location.pathname + '#'+ i +'" style="background-image: url(' + slika + ')"><span>' + naslov + '</span></a>');

      i++;
    });

    if ($('.sidebar').length) {
      var sticky = new Waypoint.Sticky({
        element: $('.sidebar'),
        stuckClass: 'stuck',
        offset: '100px'
      });

      var $section = $('.with-sidebar > h3');

      $section.waypoint(function(direction) {
          if (direction == 'down') {
            $('.sidebar a').removeClass('active');
            $('.sidebar').find('a[href="'+ window.location.pathname +'#'+ this.element.id +'"]').addClass('active');
          }
      }, {
        offset: '100px'
      });

      $section.waypoint(function(direction) {
          if (direction == 'up') {
            $('.sidebar a').removeClass('active');
            $('.sidebar').find('a[href="'+ window.location.pathname +'#'+ this.element.id +'"]').addClass('active');
          }
      }, {
        offset: '99px'
      });
    }

    $('.sidebar a[href*="#"]').click(function(event) {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

        var $scrolltop = 100;

        if (target.length) {
          event.preventDefault();

          $('html, body').animate({
            scrollTop: target.offset().top - $scrolltop
          }, 1000);
        }
      }
    });
  });

})(jQuery);